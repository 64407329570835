<template>
  <div class="flex items-center hover:bg-gray-100 py-5">
    <div class="flex w-3/6">
      <!-- product -->
      <!-- <div class="w-20">
        <img
          class="h-24"
          src="https://drive.google.com/uc?id=18KkAVkGFvaGNqPy2DIvTqmUH_nk39o3z"
          alt=""
        />
      </div> -->
      <div class="flex flex-col justify-between flex-grow">
        <span class="font-bold text-sm">{{ item.product.name }}</span>
        <!-- <span class="text-red-500 text-xs">{{Apple}}</span> -->
        <button
          class="
            font-semibold
            hover:text-red-500
            text-gray-500 text-xs text-left
          "
          @click="this.$emit('removed')"
        >
          Sterge
        </button>
      </div>
    </div>
    <div class="flex flex-col md:flex-row items-center justify-center w-1/6">
      <button @click="this.$emit('decreased')">
        <svg class="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
          <path
            d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
          />
        </svg>
      </button>

      <p class="m-2 border text-center w-8">{{ item.quantity }}</p>

      <button @click="this.$emit('increased')">
        <svg class="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
          <path
            d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
          />
        </svg>
      </button>
    </div>
    <span class="text-center w-1/6 font-semibold text-sm">
      {{ formatPrice(item.price) }}
    </span>
    <!-- <span class="text-center w-1/6 font-semibold text-sm">
      {{ formatPrice(item.tax) }}
    </span> -->
    <span class="text-center w-1/6 font-semibold text-sm">
      {{ formatPrice(item.total) }}
    </span>
  </div>
</template>

<script>
import { priceFormatter } from "@/helpers";

export default {
  props: {
    item: Object,
  },
  emits: ["increased", "decreased", "removed"],

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
  },
};
</script>

<style></style>
