<template>
  <div class="px-4 py-10 border rounded">
    <div class="flex justify-between border-b pb-5">
      <h2 class="font-medium text-2xl">Produse</h2>
      <!-- <span class="font-medium text-xl">{{ cartCountItems }}</span> -->
    </div>
    <div class="flex mt-5">
      <h3 class="font-medium text-gray-600 text-sm capitalize w-3/6">Produs</h3>
      <h3
        class="
          font-medium
          text-center text-gray-600 text-sm
          capitalize
          w-1/6
          text-center
        "
      >
        Cantitate
      </h3>
      <h3
        class="
          font-medium
          text-center text-gray-600 text-sm
          capitalize
          w-1/6
          text-center
        "
      >
        Pret
      </h3>
      <!-- <h3
        class="
          font-medium
          text-center text-gray-600 text-sm
          capitalize
          w-1/6
          text-center
        "
      >
        TVA
      </h3> -->
      <h3
        class="
          font-medium
          text-center text-gray-600 text-sm
          capitalize
          w-1/6
          text-center
        "
      >
        Total
      </h3>
    </div>

    <cart-show-list-item
      v-for="item in items"
      :key="item.id"
      :item="item"
      @increased="$emit('increased', item)"
      @decreased="$emit('decreased', item)"
      @removed="$emit('removed', item)"
    ></cart-show-list-item>
  </div>
</template>

<script>
import CartShowListItem from "./CartShowListItem.vue";

export default {
  components: { CartShowListItem },
  props: {
    items: {
      type: Array,
    },
  },
};
</script>

<style></style>
