<template>
  <div class="bg-white px-4 py-10 rounded">
    <h2 class="font-semibold text-2xl border-b pb-5">Sumar comanda</h2>
    <div class="flex justify-between mt-10 mb-5">
      <span class="text-gray-700 font-semibold text-sm uppercase">
        Cost produse
      </span>
      <span class="font-semibold text-sm">{{ formatPrice(subtotal) }}</span>
    </div>

    <!-- <div class="flex justify-between my-5">
      <span class="text-gray-700 font-normal text-sm uppercase">TVA</span>
      <span class="font-semibold text-sm">{{
        formatPrice(tax)
      }}</span>
    </div> -->

    <div class="flex justify-between my-5">
      <span class="text-gray-700 font-normal text-sm uppercase">Cost transport</span>
      <span class="font-semibold text-sm">{{ formatPrice(shipping) }}</span>
    </div>


    <div class="border-t mt-8">
      <div class="flex font-semibold justify-between py-6 text-sm uppercase">
        <span>Total</span>
        <span>{{ formatPrice(total) }}</span>
      </div>
      <router-link
        :to="{ name: 'checkout.summary' }"
        class="
          w-full
          uppercase
          inline-flex
          justify-center
          py-2
          px-4
          border border-transparent
          shadow-sm
          rounded
          text-sm
          font-medium
          text-white
          bg-yellow-600
          hover:bg-yellow-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-yellow-500
        "
      >
        Checkout
      </router-link>
    </div>
  </div>
</template>

<script>
import { priceFormatter } from "@/helpers";

export default {
  props: {
    subtotal: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    discountTotal: {
      type: Number,
      default: 0,
    },
    shipping: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
  },
};
</script>

<style></style>
