<template>
  <app-layout>
    <v-container>
      <page-header>
        <page-title> Cosul meu </page-title>
      </page-header>
      <loader v-if="isLoading" />
      <no-results v-else-if="!items.length" />
      <div v-else class="flex flex-col md:flex-row md:space-x-3">
        <cart-show-list
          class="w-auto md:w-3/4"
          :items="items"
          @increased="increase($event)"
          @decreased="decrease($event)"
          @removed="remove($event)"
        />

        <cart-show-summary
          class="w-auto md:w-1/4"
          :subtotal="cart.subtotal"
          :shipping="cart.shipping"
          :total="cart.total"
        ></cart-show-summary>
      </div>
    </v-container>
  </app-layout>
</template>

<script>
import CartShowSummary from "../components/CartShowSummary.vue";

import AppLayout from "../layout/AppLayout.vue";
import PageHeader from "../components/PageHeader.vue";
import PageTitle from "../components/PageTitle.vue";
import VContainer from "../components/VContainer.vue";
import cartService from "../services/cartService";
import CartShowList from "../components/CartShowList.vue";
import Loader from "../components/Loader.vue";
import NoResults from "../components/NoResults.vue";

export default {
  components: {
    AppLayout,
    CartShowSummary,
    PageHeader,
    PageTitle,
    VContainer,
    CartShowList,
    Loader,
    NoResults,
  },

  data() {
    return {
      cart: {},
      items: [],
      isLoading: false,
    };
  },

  methods: {
    activateLoader() {
      this.isLoading = true;
    },

    disableLoader() {
      this.isLoading = false;
    },

    setQuantity(item, quantity) {
      if (quantity < 1) {
        this.remove(item);
        return;
      }

      this.activateLoader();

      cartService
        .updateCartQuantity(item.id, quantity)
        .then(() => this.refreshCart())
        .catch((error) => {
          console.log(error);
          this.disableLoader();
        });
    },

    decrease(item) {
      this.activateLoader();
      this.setQuantity(item, --item.quantity);
    },

    increase(item) {
      this.activateLoader();
      this.setQuantity(item, ++item.quantity);
    },

    remove(item) {
      this.activateLoader();
      cartService
        .destroyItem(item.id)
        .then(() => this.refreshCart())
        .catch((error) => {
          console.log(error);
          this.disableLoader();
        });
    },

    refreshCart() {
      this.activateLoader();
      cartService
        .show()
        .then((response) => {
          if (response.data) {
            this.cart = response.data;
            this.items = response.data.items;
          }

          this.disableLoader();
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            // console.log(error);
          }
          
          this.disableLoader();
        });
    },
  },
  created() {
    this.refreshCart();
  },
};
</script>

<style></style>
